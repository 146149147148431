
import Vue, { PropType } from "vue";

import { Course } from "@prestonly/preston-common";
import { DialogCloseType, DialogI } from "@/types/dialog";

import BaseCourseForm from "@/components/form/BaseCourseForm.vue";
import BaseDialog from "@/components/dialog/BaseDialog.vue";

export default Vue.extend({
  name: "CreateCourseDialog",
  components: {
    BaseCourseForm,
    BaseDialog,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  data: function (): {
    course: Partial<Course>;
  } {
    return {
      course: {
        name: "",
        description: "",
        level: undefined,
        active: undefined,
      },
    };
  },
  methods: {
    saveChanges(course?: Partial<Course>) {
      this.course = { ...this.course, ...course };
    },
    submitForm() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          course: this.course,
        },
      });
    },
  },
});
